.unsavedModal {
  background-color: #555 !important;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modalText {
    text-align: center;
    margin: 20px 0;
  }
  .innerColumn{
    margin-left: 1rem;
    & > * {
      margin-bottom: 3rem;
    }
    &> *:last-child {
      margin-bottom: 2.5rem;
    }
  }
  .link {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
  .fileButton {
    & > * {
      cursor: pointer;
    }
    &:hover {
      button {
        color: #212529;
        background-color: #e0a800;
        border-color: #d39e00;
      }
    }
  }
  .saveButton {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #555;
      background-color: #fff;
      border: 2px solid #fff;
    }
  }
  .modalButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > * {
      margin: 0 5px;
    }
  }
}