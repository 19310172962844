html,
body {
  background-color: #666666;
}

.App {
  min-height: 100%;
  padding: 3% 6%;
  background-color: #666;
  display: flex;
}

div.DraftEditor-wrapper {
  background-color: #333;
  position: absolute;
  width: 100%;
  padding: 40px 17px 40px 0;
  font-family: 'Mulish', sans-serif;
}

div.DraftEditor-root {
  border: 0;
  height: 100%;
  /* min-height: 480px;
  max-height: 480px; */
  width: 100%;
  overflow-y: scroll;
  padding: 0 40px;
  color: #fff;
  scrollbar-width: thin;
  scrollbar-color: #ffffff #9e9e9e;
}

div.scrollbarBg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  background-color: #9e9e9e;
  z-index: -1;
}

.scrollIcon {
  position: absolute;
  right: 20px;
  color: #fff;
  font-size: 14pt;
  transform: translate(50%) scaleX(1.1);
  cursor: pointer;
}

.scrollIcon:active {
  color: #ededed;
}

.scrollUp {
  top: 10px;
}

.scrollDown {
  bottom: 10px;
}

.scrollPlaceholder {
  width: 6px;
  background: #ffffff;
  position: absolute;
  top: 40px;
  bottom: 40px;
  right: 20px;
  transform: translate(50%);
  z-index: -1;
}

div.DraftEditor-root::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

div.DraftEditor-root::-webkit-scrollbar-button {
  /*width: 48px;*/
  /*height: 48px;*/
}

div.DraftEditor-root::-webkit-scrollbar-thumb {
  background: #ffffff;
  border: 0 solid #ffffff;
  border-radius: 0;
}

div.DraftEditor-root::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

div.DraftEditor-root::-webkit-scrollbar-thumb:active {
  background: #ededed;
}

div.DraftEditor-root::-webkit-scrollbar-track {
  background: #c4c4c4;
  border: 0 solid #ffffff;
  border-radius: 0;
}

div.DraftEditor-root::-webkit-scrollbar-track:hover {
  background: #c4c4c4;
}

div.DraftEditor-root::-webkit-scrollbar-track:active {
  background: #c4c4c4;
}

div.DraftEditor-root::-webkit-scrollbar-corner {
  /*background: transparent;*/
}

.editorBlock {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* aspect-ratio: 16 / 9;
  min-height: 560px; */
  z-index: 800;
  /* flex-grow: 3; */
}

.editorReadOnlyOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 810;
}

.modal-2nd-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 790;
  display: none;
  overflow: hidden;
  outline: 0;
}

.paintSpace {
  width: 100%;
  box-sizing: border-box;
}

.hue-pointer {
  display: block;
  width: 15px;
  height: 6px;
  transform: translateY(-3px);
  border: 1px solid black;
}

.saturation-pointer {
  display: block;
  width: 10px;
  height: 10px;
  transform: translate(-5px, -5px);
  border: 1px solid black;
  border-radius: 5px;
}

.iconSize {
  width: 40px;
  height: 40px;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

.drawingWrapper {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: calc(100% - 50px);
}

.drawingWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.drawingWrapper::-webkit-scrollbar {
  display: none;
}

.drawingItemsSpace {
  overflow: hidden; /* no drawings outside of the working area */
  position: relative;
}

.drawingItem {
  position: absolute;
  pointer-events: all;
}

.activeDrawing.activeDrawing {
  border: 1px solid #888;
  z-index: 7;
  margin-top: -1px;
  margin-left: -1px;
}

.btn-circle {
  flex-shrink: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle:disabled {
  background-color: #d4822f;
  border: none !important;
}

.btn-circle.activeStyle {
  border: 3px solid #fff;
}

/* Used in settings */
.btn-circle.btn-xs {
  width: 32px;
  height: 32px;
  padding: 4px 0px;
  border-radius: 15px;
  font-size: 8px;
}

.btn-circle.btn-sm {
  width: 40px;
  height: 40px;
  padding: 6px 0px;
  border-radius: 20px;
  font-size: 8px;
}

.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
}

.btn-default {
  color: #fff;
  background-color: #d4822f;
  border-color: #d4822f;
}

.btn-secondary {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
}

.btn-ellipse.btn-sm {
  height: 40px;
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 15px;
  text-align: center;
}

.btn-ellipse.btn-md {
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
}

.btn-ellipse.btn-xl {
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}

.btn-square.btn-sm {
  height: 40px;
  padding: 8px 15px;
  min-width: 150px;
  border-radius: 0;
  font-size: 15px;
  text-align: center;
}

.btn-square.btn-md {
  height: 50px;
  padding: 7px 10px;
  min-width: 200px;
  border-radius: 0;
  font-size: 10px;
  text-align: center;
}

.btn-square.btn-xl {
  height: 70px;
  padding: 10px 16px;
  min-width: 250px;
  border-radius: 0;
  font-size: 12px;
  text-align: center;
}

/* Style for grey settings & help buttons */
.btn-settings {
  background-color: #8a98ba !important;
  border-color: #8a98ba !important;
}

.btn-settings:hover {
  background-color: #d2d2d2 !important;
  border-color: #d2d2d2 !important;
}

.btn-settings:focus {
  box-shadow: none !important;
}

.btn-settings.active {
  border-color: #fff !important;
  background-color: #fff !important;
}

.child-mw-150px > div {
  min-width: 150px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.child-mw-150px > div.solo {
  min-width: 60px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.logoWrapper {
  min-width: 0;
}

.presentation-mode .scrollbarBg,
.presentation-mode .scrollIcon {
  visibility: hidden;
}

.presentation-mode div.DraftEditor-root {
  overflow: hidden;
}

@media screen and (min-width: 481px) and (max-width: 1200px) {
  div.DraftEditor-root::-webkit-scrollbar {
    position: absolute;
    width: 26px;
    height: 26px;
  }
  div.scrollbarBg {
    right: 10px;
    width: 40px;
  }
  div.scrollbarBg::after {
    content: '';
    position: absolute;
    right: -10px;
    width: 10px;
    height: 100%;
    background-color: #666666;
  }
  .scrollUp {
    right: 30px;
  }

  .scrollDown {
    right: 30px;
  }
}

@media screen and (max-width: 1080px) {
  .child-mw-150px > div.solo {
    min-width: 150px;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}

@media screen and (max-width: 920px) {
  .logoWrapper {
    min-width: 360px;
  }
}

@media screen and (max-width: 759.99px) {
  .logoWrapper {
    min-width: 320px;
  }

  .App {
    padding: 2% 1%;
  }
}

/*.modal-dialog{*/
/*    max-width: 500px;*/
/*    margin: 1.75rem auto;*/
/*}*/

@media screen and (min-width: 680px) and (max-width: 940px) {
  .logoWrapper {
    min-width: 600px;
  }
}

@media screen and (max-width: 767.98px) {
  .text-formatting-row {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1200px) {
  .keyboardWrapper {
    min-width: 260px;
    margin-left: 1.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .keyboardWrapper {
    min-width: 160px;
    margin-left: 1.5rem;
  }
}
