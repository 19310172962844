.recordWrapper {
  border: 2px solid #777;
  border-radius: 34px;
  margin-top: -8px;
  padding: 4px;
}

.stopWatch {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  padding: 10px;
}
