.settingsModal {
  background-color: #555 !important;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .closeIcon {
    display: block;
    width: 25px;
    height: 25px;
    margin-left: auto;
    background-image: url(../../../assets/X_ClosingWindow.svg);
    background-size: contain;
    font-size: 0;
    cursor: pointer;
  }

  .modalText {
    text-align: center;
    margin: 20px 0;
  }
  .innerColumn {
    margin-left: 1rem;
    & > * {
      margin-bottom: 3rem;
    }
    & > *:last-child {
      margin-bottom: 2.5rem;
    }
  }
  .link {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
  .fileButton {
    & > * {
      cursor: pointer;
    }
    &:hover {
      button {
        color: #212529;
        background-color: #e0a800;
        border-color: #d39e00;
      }
    }
  }
  .saveButton {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #555;
      background-color: #fff;
      border: 2px solid #fff;
    }
  }
  .modalButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > * {
      margin: 0 5px;
    }
  }

  @media screen and (max-width: 576px) {
    max-width: 300px !important;
    margin: 2.5rem auto !important;

    .innerColumn {
      margin-left: 2.8rem;
    }
  }
}
