.bulletSelector {
  .dash {
    width: 20px;
    height: 2px;
    background-color: #fff;
    margin: 0 2px;
  }

  .circleWrapper {
    position: relative;

    .circle {
      cursor: pointer;
      transition: 0.1s transform;

      &:hover {
        transform: scale(1.6);
      }
    }
    .label{
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 10pt;
    }
  }
}
