.colorsPanelWrapper{
  @media screen and (min-width: 1200px) {
    &{
      width: 260px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    &{
      width: 160px;
    }
  }
}

.colorsPanel {
  width: 100%;
  display: block;
  flex-wrap: wrap;
  list-style: none;
  border: 2px solid #888;
  border-radius: 20px;
  padding: 20px;

  @media screen and (max-width: 767.98px) {
    &{
      padding: 12px;
      border-radius: 40px;
    }
  }

  .colorItem {
    cursor: pointer;
    color: #fff;
    margin-bottom: 10px;
    position: relative;

    &.activeColor {
      background-color: #888;
      border-radius: 20px;
      &:before {
        width: 46px;
        height: 46px;
        content: '';
        display: block;
        background-color: #fff;
        border-radius: 23px;
        position: absolute;
        left: -3px;
        top: -3px;
      }
      @media screen and (max-width: 767.98px) {
        &:before {
          width: 38px;
          height: 38px;
          content: '';
          display: block;
          background-color: #fff;
          border-radius: 19px;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .colorCircle{
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
        }
      }
    }

    .titleEditor {
      max-width: 65%;
      background-color: rgba(0,0,0,0);
      border: 0;
      color: #fff
    }

    .colorCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      flex-shrink: 0;
      z-index: 1;
    }

    @media screen and (max-width: 767.98px) {
      .colorCircle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        flex-shrink: 0;
        z-index: 1;
      }
    }

    @media screen and (max-width: 767.98px) {
      .colorCircle {
        margin-right: 0;
      }
    }

    .colorTitle {
      min-width: 50%;
      min-height: 24px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.helpText {
  text-align: center;
  color: #fff;
  line-height: 1.25;
  font-size: 9pt;
  max-width: 80%;
  display: block;
  margin: 0.5rem auto;

  .heading{
    font-size: 10pt;
  }
}

.dColorsTip{
  display: block;
  @media screen and (max-width: 767.98px) {
    &{
      display: none !important;
    }
  }
}

.background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 900;
}



