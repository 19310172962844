.root {
  position: relative;
  display: inline;
}

.icon {
  cursor: pointer;
}

.commentWrapper {
  position: absolute;
  display: flex;
  align-items: start;
  color: #fff;

  .deleteIcon {
    user-select: none;
    position: absolute;
    width: 34px !important;
    height: 34px !important;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
  }

  .xButton {
    position: absolute;
    right: 2px;
    top: 2px;
    color: #888;
    cursor: pointer;
  }

  .commentField {
    background-color: #444;
    font-size: 20px;
    outline: 1px solid #888;
    color: #fff;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .commentField::-webkit-scrollbar {
    display: none;
  }
}
