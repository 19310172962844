.radioSelector {
  margin-top: 0.4rem;
  padding-left: 3.2rem;
  cursor: pointer;

  .caption{
    margin-left: 1rem;
  }

  .circle{
    cursor: pointer;
    transition: 0.1s transform;
    &:hover{
      transform: scale(1.6);
    }
  }
}
