.drawIcon {
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 50%;
}
.movingIcon {
  top: 0;
  transform: translate(-50%, -50%);
}
.deleteIcon {
  bottom: 0;
  transform: translate(-50%, 50%);
}
