.importButtonWrapper {
  margin-bottom: 0;
  overflow: visible !important;
}

.fileButton {
  outline: none;
  & > * {
    cursor: pointer;
  }
  &:hover {
    button {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
      pointer-events: none;
    }
  }
  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
