.wrapper {
  position: absolute;
  width: 200px;
  height: 350px;
  background-color: #777;
  z-index: 909;
  left: -225px;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;

  .saturationBlock {
    width: 150px;
    height: 150px;
    position: relative;
  }

  .paletteInput {
    border: none;
    width: 100%;
  }

  .resetButton {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  z-index: 900;
}
