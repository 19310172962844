.textFieldWrapper {
  position: absolute;
  color: #fff;
  height: auto;

  .textField {
    background-color: rgba(256, 256, 256, 0);
    font-size: 0.8rem;
    resize: both;
    overflow: hidden;
    border: none;
  }
  .textField:focus {
    outline: 1px solid #444;
    border-radius: 0;
  }
  .movingIcon {
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .deleteIcon {
    user-select: none;
    position: absolute;
    width: 34px !important;
    height: 34px !important;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
