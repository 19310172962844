.buttonWrapper{
  position: relative;
  height: 40px;
}
.textCaption{
  position: absolute;
  top: 115%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  font-size: 8pt;
  text-align: center;
  line-height: 1.1;
  z-index: 10;
  white-space: pre-wrap;
}
.applyMinHeight{
  min-height: initial;
  @media screen and (max-width: 1360px) {
    min-height: 80px;
  }
}