.unsavedActionWrapper {
  transition: all 0.2s;

  &:hover {
    color: rgb(212, 130, 47);
  }

  &:hover .iconWrapper {
    background-color: #e0a800;
    border-color: #d39e00;
  }

  .divBgIcon {
    width: 40px;
    height: 40px;
    background-size: cover;
  }
}