.root {
  position: relative;
  display: inline;
}

.icon {
  cursor: pointer;
  transform: scale(0.8) translate(-10%, -50%);
}

.commentWrapper {
  position: absolute;
  top: 36px;
  left: 36px;
  display: flex;
  align-items: start;
  color: #fff;

  .commentField {
    background-color: rgba(256, 256, 256, 0.1);
    font-size: 0.8rem;
  }
}
