.defaultModal {
  background-color: #555 !important;
  max-width: 250px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;

  .modalText {
    text-align: center;
    margin: 20px 0;
    white-space: pre-wrap;
  }

  .saveButton {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
  .modalButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
      margin: 5px 0;
    }
  }
}
